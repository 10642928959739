'use client'

import React, { useEffect, useRef, useState } from 'react'
import { trackQuizAnswer, trackQuizShown, trackSubscribeViaEmailCap, trackSubscribeViaSMSCap, trackViewedEmailCap } from 'events/index'
import { stripPhone, validateEmail, validatePhone } from 'utils/validators'
import { ModalWrapper } from './ModalWrapper'
import { useRouter } from 'next/navigation'
import { MiniModalProps } from 'emailcaps/MiniModal'
import { quizQuestion } from './Complete/Complete'
import { quizData as quizDataB, QuestionAbbr as QuestionAbbrB, EmailCaptureNew, PhoneCaptureNew, CompleteNew } from './NewVariant/Screens'
import { EMAIL_PROMO_QUERY, SMS_PROMO_QUERY } from 'config/url_promos'
import { klaviyoTrackQuizAnswers } from 'events/klaviyo'
import { setDataLayerVariable } from 'events/ga'
import { sha256 } from 'js-sha256'
import { QuestionNew } from './NewVariant/Screens'
import Head from 'next/head'
import useIsMobile from 'hooks/useIsMobile'
import { srcToImgix } from 'components/basic/Img'
import { usePathname } from 'next/navigation'
import { useStore } from 'stores/StoreProvider'

export interface SMSError {
	error: boolean
	message: string
}

type Step = 'question' | 'email' | 'complete' | 'phone' | 'final'

const getImages = (step: string, images: { desktopImage: string; mobileImage: string }, isSleepElixirPDP: boolean): { desktopImg: string; mobileImg: string } => {
	if (isSleepElixirPDP) {
		return {
			desktopImg: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir/sleep-elixir-ecap-desktop.png',
			mobileImg: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir/sleep-elixir-ecap-mobile.png',
		}
	}

	if (step === 'question') {
		return {
			desktopImg: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/new-ecap-abc/new-ecap-1-desktop.png',
			mobileImg: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/new-ecap-abc/new-ecap-1-mobile.png',
		}
	} else if (step === 'email') {
		return {
			desktopImg: images.desktopImage,
			mobileImg: images.mobileImage,
		}
	} else if (step === 'phone') {
		return {
			desktopImg: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/new-ecap-abc/new-ecap-3-desktop.png',
			mobileImg: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/new-ecap-abc/new-ecap-3-mobile.png',
		}
	} else {
		return {
			desktopImg: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/new-ecap-abc/new-ecap-4-desktop.png',
			mobileImg: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/new-ecap-abc/new-ecap-4-mobile.png',
		}
	}
}

const handlePromo = (): string => {
	const email = localStorage.getItem('email')
	const phone = localStorage.getItem('phone')

	if (phone) {
		return `${window.location.pathname}?${SMS_PROMO_QUERY}`
	} else if (email) {
		return `${window.location.pathname}?${EMAIL_PROMO_QUERY}`
	}

	return ''
}

export const QuizWithImage: React.FC<MiniModalProps> = (props) => {
	const router = useRouter()
	const pathname = usePathname()
	const [step, setStep] = useState<Step>('question')
	const [email, setEmail] = useState('')
	const [loading, setLoading] = useState(false)
	const [phone, setPhone] = useState('')
	const [smsError, setSmsError] = useState<SMSError>({ error: false, message: '' })
	const [answer, setAnswer] = useState<QuestionAbbrB>(QuestionAbbrB.None)
	const pageWrapper = useRef(null)
	const captureLocation = props.captureLocation
	const isSmsAB = props.region == 'us' || props.region == ''
	const isMobile = useIsMobile(1023)
	const isSleepElixirPDP = pathname.includes('/sleep-elixir')

	const hideEmailCapModal = useStore((state) => state.modal.hideEmailCapModal)

	const modalName = 'Email Cap - Improve Sleep'
	useEffect(() => {
		if (props.emailCapModalVisible) {
			trackViewedEmailCap(modalName)
		}
	}, [props.emailCapModalVisible])

	const handleClose = () => {
		hideEmailCapModal()
		const promoPath = handlePromo()
		if (promoPath) {
			router.push(promoPath)
		}
	}

	const handleCloseSMS = () => {
		if (step === 'phone') {
			setStep('final')
		} else {
			handleClose()
		}
	}

	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value)
	}

	const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPhone(event.target.value)
	}

	const emailHandler = async () => {
		const validEmail = validateEmail(email)
		const properties = {
			email: email,
			// answer: answer,
			emailCapType: `2Step - ${modalName}`,
			location: 'React Modal',
			captureLocation: captureLocation,
			image: props.imgUrl,
			description: props.description,
			vanityLink: window.location.href,
			totalDiscount: props.totalDiscount,
			discountCode: props.discountCode,
		}

		if (validEmail) {
			await trackSubscribeViaEmailCap(properties)
		}
	}

	const phoneHandler = async () => {
		const strippedPhone = stripPhone(phone)
		const passesValidation = validatePhone(strippedPhone)

		const properties = {
			email: email,
			phone: '+1' + strippedPhone,
			emailCapType: `2Step - ${modalName}`,
			location: 'React Modal',
			captureLocation: captureLocation,
			image: props.imgUrl,
			description: props.description,
			amount: props.totalDiscount,
			code: props.discountCode,
			region: props.region,
		}

		if (passesValidation) {
			return await trackSubscribeViaSMSCap(properties)
		} else {
			return { error: true, message: 'Wrong phone number format', body: null }
		}
	}

	const emailHandleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		setLoading(true)
		await emailHandler()
		setLoading(false)
		localStorage.setItem('email', email)
		setDataLayerVariable('customer_email', email)
		setDataLayerVariable('hashed_customer_email', sha256(email))
		klaviyoTrackQuizAnswers({
			Pain_point_1: answer,
			Quiz_question_type: quizQuestion,
			Snorer: answer === 'Snore' ? 'Y' : '',
		})
		if (isSmsAB) {
			setStep('phone')
		} else {
			setStep('final')
		}
	}

	const phoneHandleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		try {
			event.preventDefault()
			setSmsError({ error: false, message: '' })
			setLoading(true)
			const result = await phoneHandler()
			if (!result.success || result.body?.detail) {
				setSmsError({ error: true, message: result.error || result.body.detail || 'Oops, something is wrong with your phone number.' })
				setLoading(false)
				return
			}
			setLoading(false)

			setStep('final')
			setTimeout(handleClose, 20000)

			localStorage.setItem('phone', phone)
			setDataLayerVariable('hashed_customer_phone', sha256(phone))
			trackQuizShown()
		} catch (error) {
			setSmsError({ error: true, message: 'Something went wrong, please try again.' })
			setLoading(false)
		}
	}

	const completeHandleSubmit = (abbr: QuestionAbbrB) => {
		setStep('email')
		setAnswer(abbr)
		trackQuizAnswer(quizDataB.get(abbr).question, quizQuestion)
	}

	const emailCapConfig = {
		headerText: isSleepElixirPDP ? 'Almost there' : 'Did you know?',
		title: isSleepElixirPDP ? 'Unlock your surprise' : quizDataB.get(answer).description,
		subtitle: isSleepElixirPDP ? 'Sign up to receive your surprise and start sleeping better today' : quizDataB.get(answer).explanation,
		buttonText: `Unlock my surprise`,
		dismissText: 'I don’t want a surprise',
	}

	const smsCapConfig = {
		headerText: isSleepElixirPDP ? 'Limited time' : 'Discount unlocked',
		title: isSleepElixirPDP ? 'You’ve unlocked free shipping' : `Enjoy ${props.totalDiscount} off the Pod`,
		subtitle: isSleepElixirPDP ? 'Your offer is one click away.' : quizDataB.get(answer).description2,
		buttonText: isSleepElixirPDP ? 'Get offer' : `Get discount`,
		dismissText: isSleepElixirPDP ? 'I want to pay full price' : `I don't want ${props.totalDiscount} off`,
	}

	const questionConfig = {
		headerText: 'Limited time',
		quizQuestion: isSleepElixirPDP ? 'What’s your biggest sleep challenge?' : 'What’s your main sleep challenge?',
		title: isSleepElixirPDP ? 'Unlock a surprise' : 'A surprise is waiting for you...',
		dismissText: 'Skip',
	}

	const { desktopImg, mobileImg } = getImages(step, quizDataB.get(answer).images, isSleepElixirPDP)

	const disclaimer =
		'By signing up, you accept our privacy policy. Your personal data will be processed so that we can contact you about our products via text message. Read our Privacy Policy for more information.'

	return (
		<>
			<Head>
				<link
					rel="preload"
					as="image"
					href="https://eightsleep.imgix.net/shop_pod3_mattress_size_full.png"
				/>
				{['question', 'phone', 'complete'].map((item, i) => (
					<link
						key={`preload-${item}-${i}`}
						rel="preload"
						as="image"
						href={srcToImgix(getImages(item, null, isSleepElixirPDP)[isMobile ? 'mobileImg' : 'desktopImg'], true)}
					/>
				))}
				{Array.from(quizDataB.values())
					.filter((item) => item.abbr !== QuestionAbbrB.None)
					.map((item, i) => {
						const href = getImages('email', item.images, isSleepElixirPDP)[isMobile ? 'mobileImg' : 'desktopImg']
						return (
							<link
								key={`preload-asset-${i}`}
								rel="preload"
								as="image"
								href={srcToImgix(href, true)}
							/>
						)
					})}
			</Head>
			<ModalWrapper
				pageWrapper={pageWrapper}
				emailCapModalVisible={props.emailCapModalVisible}
				handleClose={handleClose}
				settings={props.settings}
				desktopImg={desktopImg}
				mobileImg={mobileImg}
			>
				{step === 'email' && (
					<EmailCaptureNew
						handleEmailChange={handleEmailChange}
						emailHandleSubmit={emailHandleSubmit}
						currency={props.currency}
						closeModal={handleClose}
						title={emailCapConfig.title}
						subtitle={emailCapConfig.subtitle}
						headerText={emailCapConfig.headerText}
						buttonText={loading ? 'Loading...' : emailCapConfig.buttonText}
						dismissText={emailCapConfig.dismissText}
						disclaimer={disclaimer}
					/>
				)}

				{step === 'phone' && (
					<PhoneCaptureNew
						handleSMSChange={handlePhoneChange}
						smsHandleSubmit={phoneHandleSubmit}
						currency={props.currency}
						error={smsError.error ? smsError.message : ''}
						title={smsCapConfig.title}
						subtitle={smsCapConfig.subtitle}
						buttonText={loading ? 'Loading...' : smsCapConfig.buttonText}
						closeModal={handleCloseSMS}
						dismissText={smsCapConfig.dismissText}
						headerText={smsCapConfig.headerText}
						disclaimer={disclaimer}
						checkmark={true}
					/>
				)}

				{step === 'question' && (
					<QuestionNew
						title={questionConfig.title}
						headerText={questionConfig.headerText}
						subtitle={questionConfig.quizQuestion}
						answerSubmit={completeHandleSubmit}
						dismissText={questionConfig.dismissText}
						closeModal={handleClose}
						isSleepElixirPDP={isSleepElixirPDP}
					/>
				)}

				{step === 'final' && (
					<CompleteNew
						title={isSleepElixirPDP ? 'Your exclusive email offer has been applied' : 'Your offer has been automatically applied'}
						headerText={isSleepElixirPDP ? '' : `${props.totalDiscount} off the Pod`}
						subtitle={isSleepElixirPDP ? 'The offer is automatically applied to your cart.' : undefined}
						closeModal={handleClose}
						buttonText="Shop now"
						author={isSleepElixirPDP ? 'People who take Sleep Elixir before bed feel 64% better the next day' : quizDataB.get(answer).author}
						quote={isSleepElixirPDP ? 'People who take Sleep Elixir before bed feel 64% better the next day' : quizDataB.get(answer).quote}
						isSleepElixirPDP={isSleepElixirPDP}
					/>
				)}
			</ModalWrapper>
		</>
	)
}

export default QuizWithImage
