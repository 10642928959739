import React, { FC } from 'react'
import styles from './ModalScreens.module.scss'
import { Button } from 'components/WebEv/Buttons'
import { Type } from 'components/Type'
import { Button as BasicButton } from 'components/basic/Button'
import { FastCompany, MensHealth, Time } from 'components/_const_assets/companyLogos'
import cx from 'classnames'

interface WrapperProps {
	headerText?: string
	title: TitleRenderer
	dismissText?: string
	disclaimer?: string
	children?: React.ReactNode
	closeModal?: () => void
}

export type QuestionProps = WrapperProps & {
	onSubmit?: (id?: string) => void
}

export type EmailCaptureProps = WrapperProps & {
	handleEmailChange: (email: React.ChangeEvent<HTMLInputElement>) => void
	handleEmailSubmit: (event: React.FormEvent<HTMLFormElement>) => void
	buttonText: string
}

export type PhoneCaptureProps = WrapperProps & {
	handlePhoneChange: (phone: React.ChangeEvent<HTMLInputElement>) => void
	handlePhoneSubmit: (event: React.FormEvent<HTMLFormElement>) => void
	buttonText: string
	error?: string
}

export type CompleteProps = WrapperProps & {
	buttonText: string
	answer: string
}

type TitleRenderer = {
	title: string
	render?: () => React.ReactNode
}

export const quizQuestion: TitleRenderer = {
	title: 'A surprise is waiting for you...',
	render: () => (
		<Type.Headline2 className={styles.headline}>
			<span className={styles.break_word}>A surprise is</span> waiting for you...
		</Type.Headline2>
	),
}

export const emailTitle: TitleRenderer = {
	title: 'Enter your email to unlock your surprise',
	render: () => (
		<Type.Headline2 className={styles.headline}>
			<span className={styles.break_word}>Enter your email to</span> unlock your surprise
		</Type.Headline2>
	),
}

export const phoneTitle: TitleRenderer = {
	title: 'Enter your phone number to unlock your surprise',
	render: () => <Type.Headline2 className={styles.headline}>Enter your phone number to unlock your surprise</Type.Headline2>,
}

export const getCompleteTitle = (totalDiscount: string) => ({
	title: `You've unlocked ${totalDiscount} off Pod 4 Ultra`,
	render: () => (
		<Type.Headline2 className={styles.headline}>
			<span className={styles.break_word}>You've unlocked</span> {totalDiscount} off Pod 4 Ultra
		</Type.Headline2>
	),
})

export const quizData = [
	{
		answer: 'I feel hot or cold',
		subHeader: 'Sleeping on the Pod is clinically proven to give you up to one more hour of sleep every night',
	},
	{
		answer: "I can't fall asleep",
		subHeader: 'Sleeping on the Pod is clinically proven to help you fall asleep up to 44% faster',
	},
	{
		answer: 'I wake up often',
		subHeader: 'Sleeping on the Pod is clinically proven to improve your sleep quality by up to 25%',
	},
	{
		answer: 'I snore',
		subHeader: 'Sleeping on the Pod is clinically proven to reduce snoring by up to 45%',
	},
]

const disclaimer =
	'By signing up, you accept our privacy policy. Your personal data will be processed so that we can contact you about our products via text message. Read our Privacy Policy for more information.'

export type SMSError = {
	error: boolean
	message: string
}

type StepConfig =
	| { component: React.FC<QuestionProps>; props: QuestionProps }
	| { component: React.FC<EmailCaptureProps>; props: EmailCaptureProps }
	| { component: React.FC<PhoneCaptureProps>; props: PhoneCaptureProps }
	| { component: React.FC<CompleteProps>; props: CompleteProps }

interface StepConfigParams {
	loading: boolean
	handleEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void
	handleEmailSubmit: (event: React.FormEvent<HTMLFormElement>) => void
	handlePhoneChange: (event: React.ChangeEvent<HTMLInputElement>) => void
	handlePhoneSubmit: (event: React.FormEvent<HTMLFormElement>) => void
	onSubmitQuestionAnswer: (id?: string) => void
	handleClose: () => void
	smsError: SMSError
	showSMSStep: boolean
	totalDiscount: string
	answer?: string
}

export const getStepConfigs = ({
	loading,
	handleEmailChange,
	handleEmailSubmit,
	handlePhoneChange,
	handlePhoneSubmit,
	onSubmitQuestionAnswer,
	handleClose,
	smsError,
	showSMSStep,
	totalDiscount,
	answer,
}: StepConfigParams): StepConfig[] => {
	const baseSteps: StepConfig[] = [
		{
			component: QuestionNew,
			props: {
				title: quizQuestion,
				onSubmit: onSubmitQuestionAnswer,
				dismissText: 'Skip',
				closeModal: handleClose,
			},
		},
		{
			component: EmailCaptureNew,
			props: {
				handleEmailChange,
				handleEmailSubmit,
				closeModal: handleClose,
				title: emailTitle,
				headerText: 'ALMOST THERE',
				buttonText: loading ? 'Loading...' : 'Get my surprise',
				dismissText: "I don't want a surprise",
				disclaimer,
			},
		},
	]

	if (showSMSStep) {
		baseSteps.push({
			component: PhoneCaptureNew,
			props: {
				handlePhoneChange,
				handlePhoneSubmit,
				error: smsError.error ? smsError.message : '',
				title: phoneTitle,
				buttonText: loading ? 'Loading...' : 'Get my surprise',
				closeModal: handleClose,
				dismissText: "I don't want a surprise",
				headerText: 'LAST STEP',
				disclaimer,
			},
		})
	}

	baseSteps.push({
		component: CompleteNew,
		props: {
			title: getCompleteTitle(totalDiscount),
			headerText: 'LAST STEP',
			closeModal: handleClose,
			buttonText: 'Activate discount',
			answer,
		},
	})

	return baseSteps
}

export const QuestionNew: FC<QuestionProps> = (props) => (
	<div className={styles.ecap_content_wrapper}>
		<div className={styles.question_container}>
			<Type.Eyebrow className={styles.eyebrow}>{props.headerText}</Type.Eyebrow>
			{props.title.render()}
			<div className={styles.children_wrapper}>
				<div className={styles.quiz}>
					{quizData
						.filter((it) => !!it)
						.map((data, index) => (
							<Button.Secondary
								key={`quiz-question-${index}`}
								id={`quiz-question-${index}`}
								onClick={() => props.onSubmit(data.answer)}
								className={styles.quiz_button}
							>
								<Type.Body1 as="span">{data.answer}</Type.Body1>
							</Button.Secondary>
						))}
				</div>
				<div>
					{props.dismissText && (
						<BasicButton
							content={props.dismissText}
							className={styles.dismiss}
							unstyled
							onClick={() => props.onSubmit()}
						/>
					)}
				</div>
			</div>
		</div>
		<Type.SmallPrint className={styles.disclaimer}>{props.disclaimer}</Type.SmallPrint>
	</div>
)

export const EmailCaptureNew: FC<EmailCaptureProps> = (props) => (
	<div className={styles.ecap_content_wrapper}>
		<div className={styles.screen_container}>
			<Type.Eyebrow className={styles.eyebrow}>{props.headerText}</Type.Eyebrow>
			{props.title.render()}
			<div className={styles.children_wrapper}>
				<form
					className={styles.email_form}
					onSubmit={props.handleEmailSubmit}
				>
					<input
						required
						type="email"
						placeholder="Email address *"
						onChange={props.handleEmailChange}
						aria-label="Your email address input"
						// eslint-disable-next-line jsx-a11y/no-autofocus
						autoFocus
						autoComplete="email"
						className={styles.email_form_input}
					/>
					<Button.Secondary
						id={'email-submit-button'}
						type="submit"
						className={styles.email_form_button}
					>
						{props.buttonText}
					</Button.Secondary>
				</form>
				<div>
					{props.dismissText && (
						<BasicButton
							content={props.dismissText}
							className={styles.dismiss}
							unstyled
							onClick={() => props.closeModal()}
						/>
					)}
				</div>
			</div>
		</div>
		<Type.SmallPrint className={styles.disclaimer}>{props.disclaimer}</Type.SmallPrint>
	</div>
)

export const PhoneCaptureNew: FC<PhoneCaptureProps> = (props) => (
	<div className={styles.ecap_content_wrapper}>
		<div className={styles.phone_form_container}>
			<Type.Eyebrow className={styles.eyebrow}>{props.headerText}</Type.Eyebrow>
			{props.title.render()}
			<div className={styles.children_wrapper}>
				<form
					className={styles.email_form}
					onSubmit={props.handlePhoneSubmit}
				>
					<input
						type="tel"
						placeholder="Phone number (optional)"
						onChange={props.handlePhoneChange}
						aria-label="Your phone number input"
						// eslint-disable-next-line jsx-a11y/no-autofocus
						autoFocus
						autoComplete="tel"
						className={styles.email_form_input}
					/>
					{props.error && <p className={styles.error}>{props.error}</p>}
					<Button.Secondary
						id={'phone-submit-button'}
						type="submit"
						className={styles.email_form_button}
					>
						{props.buttonText}
					</Button.Secondary>
				</form>
				<div>
					{props.dismissText && (
						<BasicButton
							content={props.dismissText}
							className={styles.dismiss}
							unstyled
							onClick={() => props.closeModal()}
						/>
					)}
				</div>
			</div>
		</div>
		<Type.SmallPrint className={styles.disclaimer}>{props.disclaimer}</Type.SmallPrint>
	</div>
)

export const CompleteNew: FC<CompleteProps> = (props) => {
	const answer = quizData.find((it) => it.answer === props.answer)

	return (
		<div className={cx(styles.ecap_content_wrapper, styles.complete_screen_container)}>
			<div className={styles.screen_container}>
				{props.title.render()}
				<Type.Headchapter className={styles.headchapter}>{answer?.subHeader}</Type.Headchapter>
				<div className={styles.children_wrapper}>
					<Button.Secondary
						id={'ecap-quiz-final-step'}
						onClick={props.closeModal}
					>
						{props.buttonText}
					</Button.Secondary>
				</div>
			</div>
			<div>
				<Type.Eyebrow className={styles.eyebrow}>TRUSTED BY</Type.Eyebrow>
				<div className={styles.awards_container}>
					<AwardContainer>{FastCompany('black')}</AwardContainer>
					<AwardContainer>{MensHealth('#F70000')}</AwardContainer>
					<AwardContainer>{Time('#FF000C')}</AwardContainer>
				</div>
			</div>
		</div>
	)
}

const AwardContainer: FC<{ children: React.ReactNode }> = (props) => {
	return (
		<div className={styles.award_container}>
			<div className={styles.award_logo}>{props.children}</div>
		</div>
	)
}
