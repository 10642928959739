'use client'

/* eslint-disable @next/next/no-img-element */
import { feb27EcapAB } from 'ab_testing'
import { useScrollThreshold } from 'components/_hooks/useScrollThreshold'
import { useUTMParams } from 'components/_hooks/useUTMParams'
import { partnerVanities } from 'config/partnerVanities'
import { SiteSettings } from 'config/types'
import { YOUTUBE_UTM_CONTENT } from 'config/url_promos'
import { MiniModalProps } from 'emailcaps/MiniModal'
import useABTestVariant from 'hooks/useABTestVariant'
import { usePathname, useSearchParams } from 'next/navigation'
import { PriceManager } from 'prices'
import { Currency } from 'prices/types'
import { useEffect, useState } from 'react'
import * as eventNames from 'stores/modal/modal.constants'
import { useCurrency, useGetUpToDiscountNumber } from 'stores/prices'
import { useCurrentRegion } from 'stores/settings'
import { useStore } from 'stores/StoreProvider'
import { EMAIL_SMS_DISCOUNT_AMOUNT, EMAIL_SMS_DISCOUNT_CODE, MEMBERS_DISCOUNT_CODE, REFERRAL_COUPON } from 'stripe_lib/discounts'
import { CaptureLocation } from 'utils/events'
import { regions, RegionShort } from 'utils/internationalization'
import { ChronotypeQuiz } from './ChronotypeQuiz/ChronotypeQuiz'
import { ExitIntentModal } from './ExitIntentModal/ExitIntentModal'
import { FloatingButton } from './FloatingButton'
import { MiniModal } from './MiniModal'
import { DEFAULT_MODAL_IMAGES, ModalImages, YOUTUBE_MODAL_IMAGES } from './QuizWithImage/modalImages'
import { QuizWithImage } from './QuizWithImage/QuizWithImage'
import ReferralModal from './ReferralModal/ReferralModal'

export const isPathInExcludedPages = (path: string) => {
	for (const region of regions) {
		path = path.replace(`/${region}/`, '/')
	}

	if (path.charAt(path.length - 1) !== '/') {
		path = path + '/'
	}

	for (const url of EXCLUDED_PAGES.urls) {
		if (path === url) {
			return true
		}
	}

	return false
}

export const EXCLUDED_PAGES = {
	urls: [
		'/checkout/contact/',
		'/checkout/payment/',
		'/miami/',
		'/referral/',
		'/costco-shows/',
		'/international/',
		'/member-accessories/',
		'/manuals/',
		'/accessibility/',
		'/return-policy/',
		'/warranty/',
		'/press/',
		'/product/premium-mattress/',
		'/vipaccess/',
		'/sleep-coaching-onboarding/',
		'/sleep-coaching/',
		'/travel/',
		'/hosts/',
		'/brooke/',
		'/member-referrals/',
		'/hubermanvip/',
		'/referred/',
		'/cart/',
		'/summeronice/',
		'/equinoxspa/',
		'/apl/',
		'/hyperice/',
		'/levels/',
		'/referral-terms-conditions/',
		'/finance/',
		'/future/',
		'/future',
		'/higherdose/',
		'/healf/',
		'/lifetime/',
		'/paceline/',
		'/strava/april/',
		'/strava/summerofchampions/',
		'/strava/finalsprint/',
		'/strava/risefor2025/',
		'/login/',
		'/truemed/',
		'/giveawayaceyoursleep/',
		'/ultimategiveaway/',
		'/ultimatehuman/',
		'/codiegiveaway/',
		'/sa/',
	],
	queryParams: ['utm_source=hospitality_sleep_report', 'member-accessories=1', 'utm_source=klaviyo', 'utm_source=app', 'member=pod1', 'campaign=levels', 'campaign=future'],
}

const shouldHideModal = (pathname: string, searchParams: URLSearchParams, settings: SiteSettings) => {
	const path = pathname.toLowerCase()
	const queryParams = searchParams.toString().toLowerCase()

	if (
		path === '/referral/' ||
		path.includes('/thank_you/') ||
		path.includes('/checkout/') ||
		[EMAIL_SMS_DISCOUNT_CODE.toLowerCase()].includes(settings.promoCode) ||
		settings.promoCode.endsWith('50') ||
		settings.promoCode.toUpperCase() === MEMBERS_DISCOUNT_CODE ||
		(settings.promoCode !== REFERRAL_COUPON && (isPathInExcludedPages(path) || EXCLUDED_PAGES.queryParams.find((it) => queryParams.includes(it))))
	) {
		return true
	}
	return false
}

const getModalData = ({
	isVanity,
	showExitIntent,
	settings,
	modalImages,
	key,
	amount,
	isUSorCA,
	currency,
	totalAmount,
	region,
	pathname,
	emailCapModalVisible,
}: {
	isVanity: boolean
	showExitIntent: boolean
	settings: any
	modalImages: ModalImages
	key: string
	amount: string
	isUSorCA: boolean
	currency: Currency
	totalAmount: number
	region: RegionShort
	pathname: string
	emailCapModalVisible: boolean
}): MiniModalProps => {
	const defaultConfig = {
		subtitle: settings.saleName,
		description: 'Sign up to kick off your sleep fitness journey with an exclusive offer.',
		imgUrl: modalImages.desktopImg,
		imgAlt: '',
		buttonText: 'Join the Club',
		dismissText: "I don't like good sleep",
	}

	const formattedAmount = PriceManager.formatPriceToCurrencyNoDecimal(amount, currency)

	const baseModalData: MiniModalProps = {
		...defaultConfig,
		title: 'Almost there',
		captureLocation: CaptureLocation.MODAL,
		emailCapModalVisible,
		settings: settings,
		preventMouse: !isUSorCA,
		currency: currency,
		amount: formattedAmount,
		desktopImg: modalImages.desktopImg,
		mobileImg: modalImages.mobileImg,
		discountCode: EMAIL_SMS_DISCOUNT_CODE.toLowerCase(),
		totalDiscount: PriceManager.formatPriceToCurrencyNoDecimal(totalAmount, currency),
		region: region,
		key: key,
	}

	if (showExitIntent) {
		return {
			...baseModalData,
			key: 'Exit Modal ' + key,
			captureLocation: CaptureLocation.MODAL,
			title: 'Want to unlock your surprise?',
			dismissText: "I don't like good sleep",
			imgUrl: DEFAULT_MODAL_IMAGES.desktopImg,
			imgAlt: 'Exit Intent caption',
			settings: {
				...baseModalData.settings,
			},
		}
	}

	// Check for partner modals
	const partnerVanity = Object.values(partnerVanities).find((config) => config.pathMatch(pathname))
	if (partnerVanity) {
		return {
			...baseModalData,
			key: 'Vanity Modal ' + key,
			captureLocation: CaptureLocation.MODAL,
			title: partnerVanity.emailCapSettings.title || (partnerVanity.getTitleWithAmount ? partnerVanity.getTitleWithAmount(formattedAmount) : null),
			subtitle: partnerVanity.emailCapSettings.subtitle || '',
			description: partnerVanity.emailCapSettings.description || null,
			imgUrl: partnerVanity.emailCapSettings.imageSrc,
			imgAlt: partnerVanity.emailCapSettings.imageAlt,
			settings: {
				...baseModalData.settings,
				partnerName: partnerVanity.partnerName,
				saleName: partnerVanity.saleName,
			},
		}
	}

	if (isVanity) {
		return {
			...baseModalData,
			captureLocation: CaptureLocation.MODAL,
			key: 'Vanity Modal ' + key,
			title: null,
			amount: null,
			subtitle: '',
			description: null,
			imgUrl: settings.emailCapSettings?.imageSrc || defaultConfig.imgUrl,
			imgAlt: settings.emailCapSettings?.imageAlt || defaultConfig.imgAlt,
		}
	}

	return baseModalData
}

const getModalImages = ({
	isSleepElixirPDP,
	utmContent,
	utmMedium,
	utmSource,
	pathname,
}: {
	isSleepElixirPDP: boolean
	utmContent: string | null
	utmMedium: string | null
	utmSource: string | null
	pathname: string
}): ModalImages => {
	if (isSleepElixirPDP) {
		return YOUTUBE_MODAL_IMAGES.sleepElixir
	}

	// TODO: what is 657510927517? Can we remove this?
	if (utmContent === YOUTUBE_UTM_CONTENT.ytshervinshares || (utmMedium === 'cpm' && utmSource === 'facebook' && !![657510927517].find((it) => pathname.includes(it.toString())))) {
		return YOUTUBE_MODAL_IMAGES.shervinshares
	}

	// TODO: what is 637009028806, 658234053319, 641722010309? Can we remove these?
	if (
		[YOUTUBE_UTM_CONTENT.ytmeetpod3, YOUTUBE_UTM_CONTENT.ytugcsleepfitness, YOUTUBE_UTM_CONTENT.ytwakeupenergized].includes(utmContent) ||
		(utmMedium === 'cpm' && utmSource === 'facebook' && !![637009028806, 658234053319, 641722010309].find((it) => pathname.includes(it.toString())))
	) {
		return YOUTUBE_MODAL_IMAGES.meetpod3
	}

	return DEFAULT_MODAL_IMAGES
}

export const EmailCapContainer = () => {
	const currency = useCurrency()
	const key = 'emailCapContainer' + Date.now().toString()
	const [referralModalVisible, setReferralModalVisible] = useState(true)

	const pathname = usePathname()
	const searchParams = useSearchParams()
	const { utmMedium, utmSource, utmContent, utmCampaign } = useUTMParams()
	const heroWiped = useScrollThreshold(400)

	const forceDisplayEmailCapModalShortcut = useStore((state) => state.modal.forceDisplayEmailCapModalShortcut)
	const emailCapModalVisible = useStore((state) => state.modal.emailCapModalVisible)
	const showEmailCapModalShortcut = useStore((state) => state.modal.showEmailCapModalShortcut)
	const showExitIntent = useStore((state) => state.modal.showExitIntent)

	useEffect(() => {
		// Check if the email cap modal has been triggered but the shortcut button hasn't been shown yet
		const triggerDone = sessionStorage.getItem(eventNames.emailCapModalTriggerDone)
		const shortcutDone = sessionStorage.getItem(eventNames.emailCapShortcutButtonTriggerDone)

		// Only show the shortcut if the modal has been closed (not showing) and the shortcut hasn't been shown yet
		if (triggerDone && !shortcutDone && !emailCapModalVisible) {
			forceDisplayEmailCapModalShortcut()
		}
	}, [forceDisplayEmailCapModalShortcut, emailCapModalVisible])

	const settings = useStore((state) => state.settings.settings)

	// We have vanities and partner vanities, which both lead to rendering the Vanity Modal
	const partnerVanity = Object.values(partnerVanities).find((config) => config.pathMatch(pathname))
	const isVanity = !!(settings && settings.emailCapSettings) || !!partnerVanity

	const isSleepElixirPDP = pathname.includes('/sleep-elixir')
	const currentRegion = useCurrentRegion()
	const isUSorCA = currentRegion === '' || currentRegion === 'ca'

	const getUpToDiscountNumber = useGetUpToDiscountNumber()
	const amount = PriceManager.convertToCurrency(EMAIL_SMS_DISCOUNT_AMOUNT, PriceManager.getRegionCurrency(currentRegion))
	const totalAmount = isVanity ? getUpToDiscountNumber : getUpToDiscountNumber + amount

	const isVariantB = useABTestVariant(feb27EcapAB, 1)
	const ecapVariant = isVariantB ? 1 : 0

	const isLocalizeModalVisible = useStore((state) => state.modal.localizeModalVisible)
	if (shouldHideModal(pathname, searchParams, settings) || isLocalizeModalVisible) {
		return null
	}

	const modalImages = getModalImages({
		isSleepElixirPDP,
		utmContent,
		utmMedium,
		utmSource,
		pathname,
	})

	const modalData = getModalData({
		isVanity,
		showExitIntent,
		settings,
		modalImages,
		key,
		amount,
		isUSorCA,
		currency,
		totalAmount,
		region: currentRegion,
		pathname,
		emailCapModalVisible,
	})

	const showReferralEcap = pathname.includes('/pod-cover') && (utmSource === 'friendbuy' || utmCampaign === 'friendbuy' || settings.promoCode === REFERRAL_COUPON)

	const signedUpReferral = typeof window !== 'undefined' ? settings.promoCode === REFERRAL_COUPON && sessionStorage.getItem('signedUpReferral') : false

	const isFloatingButtonVisible =
		!emailCapModalVisible &&
		showEmailCapModalShortcut &&
		sessionStorage.getItem(eventNames.emailCapModalTriggerDone) &&
		!sessionStorage.getItem(eventNames.emailCapShortcutButtonTriggerDone) &&
		localStorage.getItem('email') == null &&
		heroWiped

	// Only show the chronotype AB testquiz if it's not a sleep elixir pdp and not a vanity page
	if (ecapVariant && !isSleepElixirPDP && !isVanity && !showExitIntent) {
		// passing a different key each time causes the modal to re-render, so it cannot animate opening
		const { key: _, ...rest } = modalData
		return (
			<div id="email_cap_root">
				<ChronotypeQuiz {...rest} />
				<FloatingButton
					isVisible={isFloatingButtonVisible}
					isPDP={pathname.includes('/product')}
				/>
			</div>
		)
	}

	if (showReferralEcap) {
		return (
			<div id="email_cap_root">
				<ReferralModal
					modalVisible={!signedUpReferral && referralModalVisible}
					currency={currency}
					saleName={settings.saleName}
					handleClose={() => {
						setReferralModalVisible(false)
						sessionStorage.setItem('signedUpReferral', 'true')
					}}
				/>
			</div>
		)
	}

	// Only show the chronotype AB testquiz if it's not a sleep elixir pdp and not a vanity page
	if (ecapVariant && !isSleepElixirPDP && !isVanity && !showExitIntent) {
		return (
			<div id="email_cap_root">
				<ChronotypeQuiz {...modalData} />
				<FloatingButton
					isVisible={isFloatingButtonVisible}
					isPDP={pathname.includes('/product')}
				/>
			</div>
		)
	}

	return (
		<div id="email_cap_root">
			{showExitIntent ? <ExitIntentModal {...modalData} /> : isVanity ? <MiniModal {...modalData} /> : <QuizWithImage {...modalData} />}

			<FloatingButton
				isVisible={isFloatingButtonVisible}
				isPDP={pathname.includes('/product')}
			/>
		</div>
	)
}

export default EmailCapContainer
