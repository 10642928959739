'use client'

import * as amplitude from '@amplitude/analytics-browser'
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser'
import { userAgentEnrichmentPlugin } from '@amplitude/plugin-user-agent-enrichment-browser'
import { abtests, COOKIE_FOR_IDS, COOKIE_FOR_KEYS, getCookieABTests } from 'ab_testing'
import { getNormalizedURL } from 'components/_utils/urlUtils'
import { CF_COUNTRY_MAP, getCookie, setCookie, VANITY_SESSION_KEY } from 'config/utils'
import { vanityConfig } from 'config/vanity'
import { customQueryParamsToTrackInGA } from 'constants/events'
import { AMPLITUDE_API_KEY } from 'events/amplitude'
import { datadogReportAPICall, datadogReportError, datadogReportEvent } from 'events/datadog'
import { setDataLayerVariable } from 'events/ga'
import { trackABtests, trackClickedChat, trackFirstPageVisit, trackPageView, trackWebsiteVisit } from 'events/index'
import { kyViewedMenopauseCampaign } from 'events/klaviyo'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import { useExitIntentTimer } from 'hooks/useExitIntentTimer'
import { useParams, usePathname, useSearchParams } from 'next/navigation'
import { FC, useCallback, useEffect, useRef } from 'react'
import { pageview, set } from 'react-ga'
import ReactGAV4 from 'react-ga4'
import { useStore } from 'stores/StoreProvider'
import { getUUID } from 'utils/browser'
import { getSafeLocale } from 'utils/internationalization'
import { updateUserUTMs } from '../events'
import { useCurrency } from 'stores/prices'

const EXIT_INTENT_TIMEOUT = 90 * 1000
let exitIntentTimer = null

export const ClientSideEffects: FC = () => {
	const pathname = usePathname()
	const params = useParams()
	const searchParams = useSearchParams()

	const timerEvents = useStore((state) => state.timer.events)
	const syncPrices = useStore((state) => state.prices.syncPrices)
	const initCart = useStore((state) => state.cart.initCart)
	const displayLocalizeModal = useStore((state) => state.modal.displayLocalizeModal)
	const toggleQuizEcap = useStore((state) => state.modal.toggleQuizEcap)
	const navToggle = useStore((state) => state.nav.toggle)

	const setPromoBar = useStore((state) => state.promo.setPromoBar)
	const initPromo = useStore((state) => state.promo.initPromo)

	const lastPageTracked = useRef<string | null>(null)

	const { showExitIntentPopUp } = useExitIntentTimer()

	const trackAdBlock = useCallback(() => {
		// check localstorage for uuid; if not there, then generate one
		if (localStorage.getItem('eight-uuid')) {
			return
		} else {
			const uuid = getUUID()
			localStorage.setItem('eight-uuid', uuid)
		}
	}, [])

	const legacyTrackPageView = useCallback(() => {
		if (typeof window === 'undefined') {
			return
		}

		const vanity_json = sessionStorage.getItem(VANITY_SESSION_KEY)
		const vanityPage = typeof vanity_json === 'string' ? JSON.parse(vanity_json) : null
		if (vanityPage) {
			const utmMedium = vanityPage?.utm_medium || ''
			const utmSource = vanityPage?.utm_source || ''
			// const utmCampaign = vanityPage?.utm_campaign || ''
			// const utmContent = vanityPage?.utm_content || ''

			set({
				campaignSource: utmSource,
				campaignMedium: utmMedium,
				// campaignName: utmCampaign,
				// campaignContent: utmContent,
				location: window.location.href,
			})
			pageview(window.location.pathname)

			ReactGAV4.set({
				campaignSource: utmSource,
				campaignMedium: utmMedium,
				// campaignName: utmCampaign,
				// campaignContent: utmContent,
				location: window.location.href,
			})

			try {
				updateUserUTMs(utmMedium, utmSource)
			} catch (e) {
				datadogReportError(e, 'Track page view')
			}
		} else {
			set({
				location: window.location.href,
			})
			pageview(window.location.pathname + window.location.search)

			ReactGAV4.set({
				location: window.location.href,
			})
		}
		const fullpath = window.location.href.substr(window.location.origin.length)
		lastPageTracked.current = fullpath

		// track podsights
		try {
			window.pdst?.('view')
		} catch (e) {
			datadogReportError(e, 'Track page view')
		}

		// trackPageLoaded()
	}, [pathname])

	const initializeKustomer = useCallback(() => {
		// should map to keys in ab_testing.ts
		const testMap: { [key: string]: string } = {
			truemedAB: 'Can pay with HSA/FSA',
		}
		const allActiveTests = getCookieABTests()
			.keys.filter((test) => !test.endsWith('_0'))
			.map((test) => test.split('_')[0])

		const kustomerInterval = setInterval(() => {
			if (window.Kustomer !== undefined && document.getElementById('kustomer-ui-sdk-iframe')) {
				let scrollY = 0
				document.getElementById('kustomer-ui-sdk-iframe')?.addEventListener('click', function () {
					trackClickedChat()
				})
				document.getElementById('header-kustomer-btn')?.addEventListener('click', function () {
					const kustomer_iframe = document.getElementById('kustomer-ui-sdk-iframe')
					if (kustomer_iframe) {
						kustomer_iframe.style.visibility = 'visible'
						kustomer_iframe.style.display = 'unset'
					}
					trackClickedChat()
					scrollY = window.scrollY
				})

				window.Kustomer.addListener('onConversationCreate', (res: { conversationId: string }) => {
					window.Kustomer.describeConversation({
						conversationId: res.conversationId,
						customAttributes: {
							chatSourceStr: 'web',
							abTestNotesTxt: allActiveTests.filter((test) => !!testMap[test]).map((test) => testMap[test]),
						},
					})
				})

				window.Kustomer.addListener('onClose', () => {
					if (scrollY !== 0) {
						window.scrollTo(0, scrollY)
					}
					const kustomer_iframe = document.getElementById('kustomer-ui-sdk-iframe')
					if (kustomer_iframe) {
						kustomer_iframe.style.removeProperty('visibility')
					}
				})

				clearInterval(kustomerInterval)
			}
		}, 1000)
	}, [timerEvents])

	useEffect(() => {
		handleRouteChange()
	}, [pathname, searchParams])

	const currency = useCurrency()
	useEffect(() => {
		const uaPlugin = userAgentEnrichmentPlugin()
		const sessionReplayTracking = sessionReplayPlugin({
			sampleRate: 0.05,
		})
		amplitude.add(uaPlugin)
		amplitude.add(sessionReplayTracking)
		if (typeof window !== 'undefined') {
			amplitude.init(AMPLITUDE_API_KEY, undefined, {
				cookieOptions: { upgrade: true },
				defaultTracking: {
					attribution: true,
					pageViews: false,
					formInteractions: false,
					fileDownloads: false,
					sessions: true,
				},
			})
			window.listabtests = function () {
				return abtests.map((it) => it.key)
			}

			setDataLayerVariable('currency', currency)

			setTimeout(() => {
				if (window.location.search) {
					const urlSearchParams = new URLSearchParams(window.location.search)

					if (abtests.find((abtest) => urlSearchParams.has(abtest.key))) {
						const abKeysCookie = getCookie(COOKIE_FOR_KEYS)
						const abTestIdsCookie = getCookie(COOKIE_FOR_IDS)

						let abKeysCookieUpdated = abKeysCookie
						let abTestIdsCookieUpdated = abTestIdsCookie
						abtests.forEach((abtest) => {
							const testVariant = urlSearchParams.get(abtest.key)
							if (testVariant) {
								abKeysCookieUpdated = abKeysCookieUpdated.replace(new RegExp(`${abtest.key}_\\d+`), `${abtest.key}_${testVariant}`)
								if (!abKeysCookieUpdated.includes(`${abtest.key}_${testVariant}`)) {
									abKeysCookieUpdated += `;${abtest.key}.${testVariant}`
								}

								abTestIdsCookieUpdated = abTestIdsCookieUpdated.replace(new RegExp(`${abtest.id}.\\d+`), `${abtest.id}.${testVariant}`)
								if (!abTestIdsCookieUpdated.includes(`${abtest.id}.${testVariant}`)) {
									abTestIdsCookieUpdated += `;${abtest.id}.${testVariant}`
								}
							}
						})

						if (abKeysCookie !== abKeysCookieUpdated && abTestIdsCookie !== abTestIdsCookieUpdated) {
							document.body.style.opacity = '0.2'
							setCookie(COOKIE_FOR_KEYS, abKeysCookieUpdated, 60, !location.hostname.startsWith('localhost') ? `.${location.hostname}` : location.hostname)
							setCookie(COOKIE_FOR_IDS, abTestIdsCookieUpdated, 60, !location.hostname.startsWith('localhost') ? `.${location.hostname}` : location.hostname)
							alert('AB tests cookie updated. Confirm to reload the page and see the changes.')
							window.location.reload()
						}
					}
				}
			}, 3000)

			if (window.location.href.includes('cx=1')) {
				sessionStorage.setItem('cx_agent', 'true')
			}

			window.sessionStorage.setItem('block-exit-intent', 'true')
			if (exitIntentTimer) {
				clearTimeout(exitIntentTimer)
			}
			exitIntentTimer = setTimeout(() => {
				window.sessionStorage.removeItem('block-exit-intent')
			}, EXIT_INTENT_TIMEOUT)

			const resetTimer = () => timerEvents.InactivityModalEvent?.resetTimer(showExitIntentPopUp)

			window.onload = resetTimer
			// DOM Events
			document.onmousemove = resetTimer
			document.onkeydown = resetTimer
			document.onmousedown = resetTimer
		}

		initCart()
		syncPrices()

		const automated_test = window.location.search.includes('automated=true') || sessionStorage.getItem('automated') === 'true'
		if (automated_test || window.location.search.includes('kustomer_test=true')) {
			sessionStorage.setItem('automated', 'true')
		}

		if (window.location.search.toLowerCase().includes('campaign') && window.location.search.toLowerCase().includes('menopause')) {
			kyViewedMenopauseCampaign()
		}

		if (!automated_test) {
			// Kustomer
			initializeKustomer()
			setTimeout(() => {
				trackWebsiteVisit()
				// Land on page PageView
				trackPageView()
				trackABtests()
				legacyTrackPageView()
			}, 3000)

			trackAdBlock()
		}

		trackFirstPageVisit()

		// parse utms
		parseUTMinfo()

		function handleShippingABQuery() {
			const query = window.location.search
			if (!query.length) {
				return
			}
			const params = new URLSearchParams(query)
			const fromCX = params.has('CX') || params.has('cx')
			const fromGoogleSearch = query.includes('utm_source=google&utm_medium=shopping')
			if (fromCX || fromGoogleSearch) {
				localStorage.setItem('excludeShippingAB', '1')
			}
		}
		handleShippingABQuery()
	}, [])

	const parseUTMinfo = useCallback(() => {
		const queryString = window.location.search
		const urlParams = new URLSearchParams(queryString)

		const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term']
		try {
			utmParams.forEach((utmParam) => {
				if (urlParams.has(utmParam)) {
					sessionStorage.setItem(utmParam, urlParams.get(utmParam))
				}
			})
		} catch (e) {
			datadogReportError(e, 'ParseUTM')
		}

		// handle variation query param for email a/b
		try {
			Object.keys(customQueryParamsToTrackInGA).forEach((utmParam) => {
				const dimension = customQueryParamsToTrackInGA[utmParam]
				if (urlParams.has(utmParam)) {
					window.ga('set', dimension, urlParams.get(utmParam))
				}
			})
		} catch (e) {
			datadogReportError(e, 'ParseUTM')
		}

		if (urlParams.has('email')) {
			sessionStorage.setItem('customer_email', urlParams.get('email'))
		}
	}, [searchParams])

	const currentPage = useStore((state) => state.settings.currentPage)
	const handleRouteChange = useCallback(() => {
		// When user change page, then snap to top
		initCart()
		navToggle(true)

		if (exitIntentTimer) {
			clearTimeout(exitIntentTimer)
		}
		window.sessionStorage.setItem('block-exit-intent', 'true')
		setTimeout(() => {
			window.sessionStorage.removeItem('block-exit-intent')
		}, EXIT_INTENT_TIMEOUT)

		const fullpath = window.location.href.substr(window.location.origin.length)

		if (lastPageTracked.current !== fullpath) {
			// prevent double tracking bug in Next router when query params are present.
			// Track google page view event
			setTimeout(() => {
				legacyTrackPageView()
			}, 1000)
		}

		// make page scrollable again (clicking mobilemenu hamburger icon makes it so you cant scroll)
		const nextFirstChild = document.getElementById('__next')?.firstChild as HTMLElement
		try {
			nextFirstChild?.style.setProperty('overflow', 'unset', 'important')
		} catch (e) {
			datadogReportError(e, 'Handle route change')
		}
		trackABtests()

		setPromoBar(currentPage)
	}, [initCart, navToggle, setPromoBar, currentPage])

	const setCurrentPage = useStore((state) => state.settings.setCurrentPage)
	const currentRegion = useStore((state) => state.settings.currentRegion)
	const setCurrentRegion = useStore((state) => state.settings.setRegion)
	const loadSettings = useStore((state) => state.settings.loadSettings)
	const setLocalizeReady = useStore((state) => state.settings.setLocalizeReady)
	const windowHistoryLength = useStore((state) => state.settings.windowHistoryLength)
	const setWindowHistoryLength = useStore((state) => state.settings.setWindowHistoryLength)
	const windowLocationHref = useStore((state) => state.settings.windowLocationHref)
	const setWindowLocationHref = useStore((state) => state.settings.setWindowLocationHref)
	useEffect(() => {
		const locale = getSafeLocale(params)
		const normalizedPath = getNormalizedURL(locale, pathname, params)
		setCurrentPage(normalizedPath)

		if (currentRegion !== locale) {
			if (typeof window !== 'undefined' && window.location.hostname.endsWith('.com.mx')) {
				setCurrentRegion('mx')
			} else {
				setCurrentRegion(['', 'us', 'eu', 'uk', 'ca', 'au', 'se', 'no', 'dk', 'ae', 'ch', 'mx'].indexOf(locale) !== -1 ? locale : '')
			}
		}

		if (normalizedPath.startsWith('/blog')) {
			const cfcountry = getCookie('cfcountry')?.toUpperCase()
			if (cfcountry && CF_COUNTRY_MAP[cfcountry] && CF_COUNTRY_MAP[cfcountry] !== currentRegion) {
				setCurrentRegion(CF_COUNTRY_MAP[cfcountry])
			}
		}

		const vanityData = vanityConfig[params.t1 as string | undefined]
		loadSettings(vanityData)

		let verifyPassTesting = false
		let automated = false

		initPromo()
		automated = sessionStorage.getItem('automated') === 'true' || window.location.search.includes('automated=true')

		// Impact clickId passing
		const queryParams = new URLSearchParams(searchParams)

		if (queryParams.get('utm_source') === 'Impact' && typeof queryParams.get('irclickid') === 'string' && queryParams.get('irclickid')?.length) {
			sessionStorage.setItem('impactClickId', queryParams.get('irclickid') as string)
		}

		// VerifyPass testing
		if (searchParams.get('verifyPass')) {
			verifyPassTesting = true
		}

		const shopCode = searchParams.get('code')
		if (shopCode) {
			sessionStorage.setItem('memberShopCode', shopCode)
		}

		gsap.registerPlugin(ScrollTrigger)
		ScrollTrigger.refresh()

		const isNotUS = currentRegion !== '' && currentRegion !== 'us'
		if (isNotUS) {
			setLocalizeReady()
			if (!automated && localStorage.getItem('confirmedRegion') == null) {
				displayLocalizeModal()
			}
		}
		handleSpecialEcap().then((flag) => {
			toggleQuizEcap(flag)
		})
	}, [pathname, searchParams, params, initPromo])

	useEffect(() => {
		if (sessionStorage.getItem('automated') === 'true') {
			return
		}

		if (window.history.length !== windowHistoryLength) {
			setWindowHistoryLength(window.history.length)
			trackPageView()
		} else if (window.location.href !== windowLocationHref) {
			setWindowLocationHref(window.location.href)
			//This will catch users going forward and backward in history
			trackPageView()
		}
	}, [])

	return null
}

const handleSpecialEcap = async () => {
	const email = localStorage.getItem('email')
	const quizEcapDone = localStorage.getItem('quizEcapDone') === 'true'
	if (email && !quizEcapDone) {
		try {
			const result = await fetch('/api/fetch-segment-member', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Cache-Control': 'no-cache',
				},
				body: JSON.stringify({ email }),
			})
			datadogReportEvent('success', 'handleSpecialEcap')
			datadogReportAPICall(result.status, 'handleSpecialEcap')

			const json = await result.json()
			if (json.success && json.result) {
				return true
			}
		} catch (e) {
			datadogReportEvent('failure', 'handleSpecialEcap', e)
		}
	}
	return false
}
