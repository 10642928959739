'use client'

import React, { FC, useEffect, useState } from 'react'

import { SiteSettings } from 'config/types'
import { EMAIL_PROMO_QUERY, SMS_PROMO_QUERY } from 'config/url_promos'
import { QuestionAbbr, quizData, quizQuestion } from 'emailcaps/QuizWithImage/Complete/Complete'
import { setDataLayerVariable } from 'events/ga'
import { trackQuizAnswer, trackSubscribeViaEmailCap, trackSubscribeViaSMSCap, trackViewedEmailCap } from 'events/index'
import { klaviyoTrackQuizAnswers } from 'events/klaviyo'
import useIsMobile from 'hooks/useIsMobile'
import { sha256 } from 'js-sha256'
import { usePathname, useRouter } from 'next/navigation'
import { PriceManager } from 'prices'
import { Currency } from 'prices/types'
import { CaptureLocation } from 'utils/events'
import { RegionShort } from 'utils/internationalization'
import { stripPhone, validateEmail, validatePhone } from 'utils/validators'
import QuizFirstContent from './Content/QuizFirstContent'
import styles from './MiniModal.module.scss'
import { Wrapper } from './Wrapper'
import { useStore } from 'stores/StoreProvider'

export interface MiniModalProps {
	emailCapModalVisible: boolean
	preventMouse?: boolean
	settings: SiteSettings
	title: string
	subtitle: string
	description: string
	imgUrl: string
	imgAlt: string
	captureLocation?: CaptureLocation
	key?: string
	currency: Currency
	amount?: string
	totalDiscount?: string
	discountCode?: string
	region?: RegionShort

	buttonText: string
	dismissText: string
	ecapABVariant?: string | null | undefined

	desktopImg: string
	mobileImg: string
	isQuizFirst?: boolean
}

export const MiniModal: FC<MiniModalProps> = (props) => {
	const router = useRouter()
	const pathname = usePathname()
	const infoSubsequence = props.region === '' || props.region === 'us' || props.region === 'ca' ? ['email', 'phone'] : ['email']
	const stepSequnce = ['quiz', ...infoSubsequence, 'complete']
	const isMobile = useIsMobile()

	const [email, setEmail] = useState<string>('')
	const [phone, setPhone] = useState('')
	const [isEmailInvalid, setIsEmailInvalid] = useState<boolean>(false)
	const [isPhoneInvalid, setIsPhoneInvalid] = useState<boolean>(false)
	const [answer, setAnswer] = useState<QuestionAbbr>()
	const [step, setStep] = useState(0)
	const [abImage, setAbIamge] = useState(
		props.settings?.ecapImg || props.imgUrl || (isMobile ? props.settings?.heroSettings?.sources?.[0]?.src : props.settings?.heroSettings?.sources?.slice(-1)[0]?.src)
	)

	const hideEmailCapModal = useStore((state) => state.modal.hideEmailCapModal)

	const modalName = props.settings.saleName ? props.settings.saleName : 'Discount'
	const captureLocation = props.captureLocation

	const nextStep = () => {
		setStep(Math.min(step + 1, stepSequnce.length - 1))
	}

	useEffect(() => {
		if (props.emailCapModalVisible) {
			trackViewedEmailCap(modalName)
		}
	}, [props.emailCapModalVisible, modalName])

	const handleClose = () => {
		const email = localStorage.getItem('email')
		const phone = localStorage.getItem('phone')

		if (phone) {
			router.push(pathname + '?' + SMS_PROMO_QUERY)
		} else if (email) {
			router.push(pathname + '?' + EMAIL_PROMO_QUERY)
		}

		hideEmailCapModal()
	}

	const handleEmailSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		const passesValidation: boolean = validateEmail(email)
		const currencySymbol = PriceManager.formatPriceToCurrencyNoDecimal(0, props.currency).replace(/[0-9]/g, '')
		const hasVanityQuote = !!props.settings.klaviyoProperties?.title

		let vanityProperties: any = {
			shouldUseVanityFlow: false,
		}

		if (props.settings.klaviyoProperties) {
			const discount = props.settings.klaviyoProperties.discount

			vanityProperties = {
				shouldUseVanityFlow: true,
				vanityQuote: hasVanityQuote ? props.settings.klaviyoProperties.title : '',
				vanityImage: hasVanityQuote ? props.settings.klaviyoProperties.image : '',
				vanityName: props.settings.partnerName || props.settings.klaviyoProperties.name,
				vanityDiscount: discount,
				totalVanityDiscount: discount,
				vanityLink: props.settings.klaviyoProperties.link,
				hasVanityQuote: hasVanityQuote,
				vanityCode: props.settings.promoCode.toUpperCase(),
			}
		}

		const properties = {
			email: email,
			emailCapType: modalName,
			location: `${modalName} Modal`,
			captureLocation: CaptureLocation.T1,
			image: props.imgUrl,
			description: props.description,
			...vanityProperties,
			vanity: true,
		}

		if (passesValidation) {
			trackSubscribeViaEmailCap(properties)
			localStorage.setItem('email', email)
			setDataLayerVariable('customer_email', email)
			setDataLayerVariable('hashed_customer_email', sha256(email))

			nextStep()

			// if (props.region === '' || props.region === 'us' || props.region === 'ca') {
			// 	setStep('phone')
			// } else {
			// 	setStep('quiz')
			// }
		} else {
			setIsEmailInvalid(true)
		}
	}

	const handlePhoneSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()

		const strippedPhone = stripPhone(phone)
		const passesValidation = validatePhone(strippedPhone)
		const sphone = '+1' + strippedPhone

		if (passesValidation) {
			trackSubscribeViaSMSCap({
				email: email,
				phone: sphone,
				answer: '',
				location: `${modalName} Modal`,
				captureLocation: captureLocation,
				region: props.region,
			})
			localStorage.setItem('phone', sphone)
			setDataLayerVariable('hashed_customer_phone', sha256(sphone))
			nextStep()
		} else {
			setIsPhoneInvalid(true)
		}
	}

	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault()
		setIsEmailInvalid(false)
		setEmail(event.target.value)
	}

	const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault()
		setIsPhoneInvalid(false)
		setPhone(event.target.value)
	}

	const handleQuizComplete = (abbr: QuestionAbbr) => {
		nextStep()
		setAnswer(abbr)
		klaviyoTrackQuizAnswers({
			Pain_point_1: abbr,
			Quiz_question_type: quizQuestion,
			Snorer: abbr === QuestionAbbr.Snore ? 'Y' : '',
		})
		trackQuizAnswer(quizData.get(abbr).question, quizQuestion)
		//setTimeout(handleClose, 20000)
	}

	const contentConfig = {
		title: props.title ?? `${props.settings.partnerName} gifted you ${props.totalDiscount} off Pod 4 Ultra`,
		ecapTitle: 'Unlock your discount',
		description: `Sign up to receive ${props.totalDiscount} off and start sleeping better today`,
		sms_description: `Your offer is one click away.`,
		amount: props.amount,
		//smsPromoTitle:isQuizFirstAB?`You’ve unlocked $props.totalDiscount, off Pod 4 Ultra`:,
		subtitle: `You've unlocked ${props.totalDiscount} off`,
		imgUrl: abImage,
		imgAlt: props.imgAlt,
		step: stepSequnce[step],
		email: email,
		phone: phone,
		isEmailInvalid: isEmailInvalid,
		isPhoneInvalid: isPhoneInvalid,
		handleEmailChange: handleEmailChange,
		handlePhoneChange: handlePhoneChange,
		handleEmailSubmit: handleEmailSubmit,
		handlePhoneSubmit: handlePhoneSubmit,
		handleQuizComplete: handleQuizComplete,
		answer: answer,
		closeModal: handleClose,
		buttonText: `Unlock your offer`,
		dismissText: "I'll pay full price",
		partnerName: props.settings.partnerName,
		saleName: props.settings.saleName,
		learnMore: () => {
			router.push('/pod-cover')
			hideEmailCapModal()
			handleClose()
		},
	}
	return (
		<Wrapper
			handleClose={handleClose}
			emailCapModalVisible={props.emailCapModalVisible}
			preventMouse={props.preventMouse}
			className={styles.t1ab}
		>
			<QuizFirstContent
				{...contentConfig}
				isQuizFirst
			/>
		</Wrapper>
	)
}
