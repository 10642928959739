import { getNormalizedURL } from 'components/_utils/urlUtils'
import { usePathname } from 'next/navigation'
import { useParams } from 'next/navigation'
import { useCallback } from 'react'
import { getSafeLocale } from 'utils/internationalization'
import * as eventNames from 'timerevents/eventNames'
import { datadogReportError } from 'events/datadog'
import { useStore } from 'stores/StoreProvider'

export const useExitIntentTimer = () => {
	const params = useParams()
	const pathname = usePathname()

	const timerEvents = useStore((state) => state.timer.events)
	const emailCapModalVisible = useStore((state) => state.modal.emailCapModalVisible)
	const hideEmailCapModal = useStore((state) => state.modal.hideEmailCapModal)
	const showEmailCapModalShortcut = useStore((state) => state.modal.showEmailCapModalShortcut)
	const toggleExitIntenEcap = useStore((state) => state.modal.toggleExitIntenEcap)

	const unpauseExitIntent = useCallback(() => {
		sessionStorage.setItem('pause_exit_intent', '0')
	}, [])

	const showExitIntentPopUp = useCallback(() => {
		const locale = getSafeLocale(params)
		const normalizedURL = getNormalizedURL(locale, pathname, params)
		const displayPopUp =
			typeof window !== 'undefined' &&
			!emailCapModalVisible &&
			hideEmailCapModal &&
			showEmailCapModalShortcut &&
			localStorage.getItem('email') == null &&
			sessionStorage.getItem(eventNames.emailCapModalTriggerDone) &&
			!sessionStorage.getItem(eventNames.emailCapShortcutButtonTriggerDone) &&
			normalizedURL.startsWith('/product') &&
			!normalizedURL.includes('/sleep-elixir')
		if (displayPopUp) {
			toggleExitIntenEcap(true)
		}
	}, [pathname, params])

	const exitIntentEvent = useCallback(() => {
		if (window.sessionStorage.getItem('block-exit-intent') === 'true') {
			return
		}

		const exitIntentEventBlocked = sessionStorage.getItem('pause_exit_intent') === '1'
		timerEvents.InactivityModalEvent?.resetTimer(showExitIntentPopUp, 500)
		if (exitIntentEventBlocked) {
			return
		}
		try {
			if (timerEvents.assistedSaleModalEvent?.canBeTriggered) {
				timerEvents.assistedSaleModalEvent.action()
			}
		} catch (e) {
			datadogReportError(e, 'Exit intent')
		}
	}, [showExitIntentPopUp, timerEvents])

	return { unpauseExitIntent, exitIntentEvent, showExitIntentPopUp }
}
